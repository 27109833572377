<template>
  <div>
    <b-modal
      v-model="isOpen"
      modal-class="delivery-request-modal"
      id="delivery-request"
      :title="$t('delivery.delivery_request')"
      centered
      hide-footer
      no-close-on-backdrop
      @close="rejecting = false"
      @cancel="rejecting = false"
    >
      <b-form @submit.prevent v-if="!rejecting">
        <b-form-group :label="$t('delivery.name')" label-for="Name">
          <b-form-input
            type="text"
            v-model="page_data.user.first_name"
            :placeholder="$t('delivery.name')"
            class="custom-input"
            disabled
          />
        </b-form-group>

        <b-form-group :label="$t('delivery.email')" label-for="Email">
          <b-form-input
            type="email"
            v-model="page_data.user.email"
            :placeholder="$t('delivery.email')"
            class="custom-input"
            disabled
          />
        </b-form-group>

        <b-form-group
          :label="$t('delivery.phone_number')"
          label-for="phone number"
        >
          <b-form-input
            type="number"
            v-model="page_data.user.phone[0].phone"
            :placeholder="$t('delivery.phone_number')"
            class="custom-input"
            disabled
          />
        </b-form-group>

        <b-form-group :label="$t('delivery.city')" label-for="city">
          <b-form-input
            type="text"
            v-model="page_data.city.name"
            :placeholder="$t('delivery.city')"
            class="custom-input"
            disabled
          />
        </b-form-group>

        <b-form-group
          :label="$t('delivery.nationality')"
          label-for="nationality"
        >
          <b-form-input
            type="text"
            v-model="page_data.nationality.name"
            :placeholder="$t('delivery.nationality')"
            class="custom-input"
            disabled
          />
        </b-form-group>
        <hr />
        <ul class="vendor-info">
          <li>
            <span>{{ $t("g.id_number") }}:</span>
            <strong>{{ page_data.user.id_number || $t("notFound") }}</strong>
          </li>
          <li>
            <span>{{ $t("g.id_residence_expiry_date") }}:</span>
            <strong>{{
              page_data.user.id_expiry_date || $t("notFound")
            }}</strong>
          </li>
          <li>
            <span>{{ $t("g.id_residence_number_image") }}:</span>
            <strong v-if="idImage">
              <a :href="idImage" target="_blank">{{ $t("g.view_file") }}</a>
            </strong>
            <strong v-else>
              {{ $t("notFound") }}
            </strong>
          </li>
          <li>
            <span>{{ $t("g.driving_license_expiry_date") }}:</span>
            <strong>{{
              page_data.licence_expiry_date || $t("notFound")
            }}</strong>
          </li>
          <li>
            <span>{{ $t("g.driving_license_image") }}:</span>
            <strong v-if="licenseImage">
              <a :href="licenseImage" target="_blank">{{
                $t("g.view_file")
              }}</a>
            </strong>
            <strong v-else>
              {{ $t("notFound") }}
            </strong>
          </li>
        </ul>

        <div class="btn-box my-2">
          <b-button
            type="submit"
            variant="primary"
            block
            @click="rejecting = true"
            class="outline"
          >
            <span>{{ $t("delivery.reject") }}</span>
          </b-button>

          <b-button
            type="submit"
            variant="primary"
            block
            @click="acceptRequest"
            class="primary-btn"
          >
            <span>{{ $t("delivery.accept") }}</span>
          </b-button>
        </div>
      </b-form>
      <div v-else-if="page_data">
        <validation-observer ref="dReject">
          <b-form>
            <b-form-group :label="$t('g.rejection_reason')">
              <validation-provider
                #default="{ errors }"
                name="Rejection reason"
                rules="required"
              >
                <b-form-textarea
                  rows="5"
                  v-model="message"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('g.rejection_reason')"
                />
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small></validation-provider
              >
            </b-form-group>
            <b-button
              v-if="
                globalPermission['delivery-boys'] &&
                globalPermission['delivery-boys'].update
              "
              style="float: inline-end"
              variant="danger"
              @click="rejectRequest"
            >
              {{ $t("g.submit") }}
            </b-button>
          </b-form>
        </validation-observer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BRow,
  BCol,
  BFormDatepicker,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
export default {
  name: "DeliveryRequest",
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormDatepicker,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    required,
  },

  props: {
    page_data: {
      required: false,
    },
    request_id: {
      required: false,
    },
  },
  data() {
    return {
      rejecting: false,
      message: null,
      isOpen: false,
    };
  },
  computed: {
    idImage() {
      return (
        this.page_data?.user?.media?.filter(
          (media) => "id_number_image" === media.name
        )[0]?.path ?? null
      );
    },
    licenseImage() {
      return (
        this.page_data?.user?.media?.filter(
          (img) => "driving_license_image" === img.name
        )[0]?.path ?? null
      );
    },
  },
  methods: {
    // async deleteRequest() {
    //   try {
    //     this.$helpers.sweetAlertConfirm(
    //       this.$t("are_you_sure"),
    //       this.$t("you_wont_be_able_to_revert_this_action"),
    //       "warning",
    //       this.$t("g.yes_delete_it"),
    //       this.$t("g.cancel-button"),
    //       () => this.rejectRequest()
    //     );
    //     this.overlayLoading = true;
    //   } catch (error) {
    //     this.isError = true;
    //   } finally {
    //     this.overlayLoading = false;
    //   }
    // },

    rejectRequest() {
      this.$refs.dReject.validate().then((success) => {
        if (success) {
          let rowData = {
            message: this.message,
          };
          this.$http
            .delete(`admin/delivery-boys/${this.request_id}`, {
              data: rowData,
              headers: {
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              if (res.status === 200 || res.status === 201) {
                this.$helpers.makeToast(
                  "success",
                  res.data.message,
                  res.data.message
                );
                this.$emit("update-table", true);
                this.$bvModal.hide("delivery-request");
                this.rejecting = false;
              }
            })
            .catch((err) => {
              this.$helpers.handleError(err);
            });
        } else {
          this.$helpers.makeToast(
            "warning",
            this.$t("g.something_is_wrong"),
            this.$t("g.please_ensure_that_the_inputs_are_correct")
          );
        }
      });
    },
    acceptRequest() {
      this.$http
        .post(`admin/delivery-boys/${this.request_id}?_method=put`, {
          is_approved: 1,
        })
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            this.$helpers.makeToast(
              "success",
              res.data.message,
              res.data.message
            );
            this.$emit("update-table", true);
            this.$bvModal.hide("delivery-request");
          }
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
  },
  watch: {
    request_id(newValue) {
      this.isOpen = true;
    },
  },
};
</script>

<style lang="scss" src="./_DeliveryRequestModel.scss"></style>
