<template>
  <Loading v-if="isLoading" />
  <div v-else class="delivery-requests-list">
    <b-card>
      <div
        class="p-2 custom-search d-flex align-items-center justify-content-between mb-1"
      >
        <h1 class="m-0 page-title">
          {{ $t("delivery.delivery_boys_requests") }}
        </h1>
      </div>
      <!-- table -->
      <vue-good-table
        :columns="tableHeader"
        :rows="tableBody"
        styleClass="table-custom-style vgt-table striped condensed"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        :search-options="{
          enabled: true,
          skipDiacritics: true,
          placeholder: $t('search_placeholder'),
        }"
      >
        <!-- Column: Name -->
        <template slot="table-column" slot-scope="props">
          <span v-if="props.column.label === 'name'" class="text-nowrap">
            {{ $t("delivery.name") }}
          </span>
          <span v-else-if="props.column.label === 'email'" class="text-nowrap">
            {{ $t("delivery.email") }}
          </span>
          <span v-else-if="props.column.label === 'city'" class="text-nowrap">
            {{ $t("delivery.city") }}
          </span>
          <span
            v-else-if="props.column.label === 'nationality'"
            class="text-nowrap"
          >
            {{ $t("delivery.nationality") }}
          </span>
          <!-- <span
            v-else-if="props.column.label === 'birthday'"
            class="text-nowrap"
          >
            {{ $t("delivery.birthday") }}
          </span> -->
          <span
            v-else-if="props.column.label === 'Actions'"
            class="text-nowrap"
          >
            {{ $t("g.action") }}
          </span>
          <span v-else>
            {{ props.column.label }}
          </span>
        </template>

        <!-- Slot: Table Row -->
        <template slot="table-row" slot-scope="props">
          <div role="button" v-on:click="openRequestModal(props.row)">
            <span
              class="text-nowrap"
              v-if="props.column.field === 'user.first_name'"
            >
              {{ props.row.user.first_name + " " + props.row.user.last_name }}
            </span>

            <span
              class="text-nowrap custom-toggle"
              v-else-if="props.column.field === '#'"
            >
              {{ props.row.index }}
            </span>

            <span
              class="text-nowrap"
              v-else-if="props.column.field === 'user.email'"
            >
              {{ props.row.user.email }}
            </span>

            <span class="text-nowrap" v-else-if="props.column.field === 'city'">
              {{ props.row.city && props.row.city.name }}
            </span>

            <span
              class="text-nowrap"
              v-else-if="props.column.field === 'nationality'"
            >
              {{ props.row.nationality && props.row.nationality.name }}
            </span>

            <!-- <span
              class="text-nowrap"
              v-else-if="props.column.field === 'user.birthdate'"
            >
              {{ props.row.user.birthdate }}
            </span> -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </div>

          <!-- Column: Action -->
          <!-- <span v-else-if="props.column.field === 'actions'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                text="Block Level Dropdown Menu"
                block
              >
                <template v-slot:button-content>
                  <feather-icon
                    size="16"
                    icon="MoreVerticalIcon"
                    class="text-body align-middle mr-25"
                  />
                </template>

                <b-dropdown-item
                  :to="{
                    name: 'edit-delivery-boy',
                    params: { id: props.row.id },
                  }"
                >
                  <feather-icon icon="EditIcon" class="mr-50" />
                  <span>{{ $t("g.edit") }}</span>
                </b-dropdown-item>
                <b-dropdown-item @click="deleteDelivery(props.row.id)">
                  <feather-icon icon="Trash2Icon" class="mr-50" />
                  <span>{{ $t("g.delete") }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span> -->

          <!-- Column: Common -->
        </template>
        <template slot="emptystate">
          <div class="empty-table-message">
            <!-- Your custom content for the empty state goes here -->
            <p>{{ $t("noData") }}</p>
          </div>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-center flex-wrap">
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
    <DeliveryRequestModel
      :page_data="page_data"
      :request_id="request_id"
      @update-table="update"
    />
  </div>
</template>

<script>
import {
  BPagination,
  BButton,
  BCard,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Loading from "@/components/shared/loading/loading.vue";
import DeliveryRequestModel from "@/components/delivery/DeliveryRequestModel/DeliveryRequestModel";

export default {
  name: "DeliveryRequestsList",
  components: {
    VueGoodTable,
    BPagination,
    BButton,
    Loading,
    BCard,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    DeliveryRequestModel,
  },
  data() {
    return {
      isLoading: false,
      pageLength: 20,
      searchTerm: "",
      page_data: null,
      delivery_phone: null,
      city: null,
      request_id: null,
      tableBody: [],
      tableHeader: [
        {
          label: "#",
          field: "#",
          sortable: false,
        },
        {
          label: "name",
          field: "user.first_name",
        },
        {
          label: "email",
          field: "user.email",
          sortable: false,
        },
        {
          label: "city",
          field: "city",
          sortable: false,
        },
        {
          label: "nationality",
          field: "nationality",
          sortable: false,
        },
      ],
    };
  },
  beforeMount() {
    this.getDeliveryBoysList();
  },
  methods: {
    async getDeliveryBoysList() {
      try {
        const response = (
          await this.$http.get("admin/delivery-boys?not_approved=true")
        ).data.data;
        this.tableBody = response;
        this.tableBody.map((el, index) => {
          el.index = index + 1;
        });
      } catch (error) {
        this.$helpers.handleError(error);
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
    async openRequestModal(delivery_data) {
      if (
        this.globalPermission["delivery-boys"] &&
        this.globalPermission["delivery-boys"].show
      )
        try {
          this.request_id = delivery_data.id;
          this.page_data = delivery_data;
          this.$bvModal.show("delivery-request");
        } catch (err) {}
    },
    update() {
      this.getDeliveryBoysList();
    },
    // async deleteNotification(NotificationId) {
    //   try {
    //     this.$helpers.sweetAlertConfirm(
    //       this.$t("are_you_sure"),
    //       this.$t("you_wont_be_able_to_revert_this_action"),
    //       "warning",
    //       this.$t("g.yes_delete_it"),
    //       this.$t("g.cancel-button"),
    //       () => this.handledeleteNotification(NotificationId),
    //       "The Notification has been successfully deleted"
    //     );
    //     this.overlayLoading = true;
    //   } catch (error) {
    //     this.isError = true;
    //   } finally {
    //     this.overlayLoading = false;
    //   }
    // },
    // handledeleteNotification(NotificationId) {
    //   this.$http
    //     .delete(`admin/Notifications/${NotificationId}`)
    //     .then((res) => {
    //       if (res.status === 200) {
    //         this.getNotificationsList();
    //       }
    //     })
    //     .catch((err) => {
    //
    //     });
    // },
  },
};
</script>
<style lang="scss">
@import "./index.scss";
</style>
